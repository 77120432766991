import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { StaticImage } from 'gatsby-plugin-image';
import { useScreenRecognition } from '@reckitt/phx-husky.hooks.use-screen-recognition';
import classNames from 'classnames';

import LdsModal from 'common/LdsModal';

import promoPageContent from '../../../content/promoPageContent.json';

import './PromoPage.scss';

const lidl = '../../../static/images/lidl.png';
const drop = '../../../static/images/drop.png';
const mainBannerMobile = '../../../static/images/mainBannerMobile.png';
const mainBanner = '../../../static/images/mainBanner.png';
const dropSupport = '../../../static/images/dropSupport.png';
const finishProductsBig = '../../../static/images/finshProductsBig.png';
const finishProductsSmall = '../../../static/images/finshProductsSmall.png';
const finish = '../../../static/images/finish.png';
const winners = '../../../static/images/winners.png';
const winnersMobile = '../../../static/images/winnersMobile.png';

const PromoPage = () => {
  const [isLdsModalShown, setLdsModalShown] = useState(false);
  const [isWinnersList, setWinnersList] = useState(false);
  const { isMobile, isTablet, isDesktop } = useScreenRecognition();

  const {
    ldsTerms: { ldsButtonName },
    toDo: {
      firstStep,
      firstStepSpan,
      secondStep,
      secondStepSpan,
      thirdStep,
      thirdStepSpan,
      lidlImgAlt,
      finishImgAlt,
    },
    toWin: { text, textSpan, amount, finishProductsImgAlt, more, moreSpan },
    learnMore: { logoLidlImgAlt, dropImgAlt, competitionTime, details, detailsMobile },
    seo: { title, description, keywords, lang },
    banner: { bannerImgAlt, bannerTitle, bannerDescription, bannerParagraph },
    lds,
    winnersText,
    modalAlt,
  } = promoPageContent;

  const toggleLdsModal = () => {
    setLdsModalShown(!isLdsModalShown);
  };

  const toggleWinnersList = () => {
    setWinnersList(!isWinnersList);
  };

  const winnersButton = classNames('winners__button', {
    'winners__button--disable': isWinnersList,
  });

  const winnersModal = classNames('winners__modal', {
    'winners__modal--show': isWinnersList,
    'winners__modal--hide': !isWinnersList,
  });

  return (
    <>
      <Helmet
        title={title}
        titleTemplate={title}
        htmlAttributes={{
          lang,
        }}
      >
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
      </Helmet>
      <div className="lidl-wrapper" id="top">
        <section className="main">
          <div className="main-content">
            <StaticImage
              src={dropSupport}
              alt={bannerImgAlt}
              className="main-content__image main-content__image--left"
            />
            <StaticImage
              src={finish}
              alt={finishImgAlt}
              className="main-content__image main-content__image--right"
            />
            <h1 className="main-content__title">{bannerTitle}</h1>
            <h2 className="main-content__description">{bannerDescription}</h2>
            <p className="main-content__paragraph">{bannerParagraph}</p>
          </div>
          {isMobile ? (
            <StaticImage src={mainBannerMobile} alt={bannerImgAlt} className="main__image" />
          ) : (
            <StaticImage src={mainBanner} alt={bannerImgAlt} className="main__image" />
          )}
        </section>
        <section className="to-do">
          <div className="to-do__finish-img">
            {isMobile || isTablet || isDesktop ? (
              <StaticImage src={finish} alt={finishImgAlt} />
            ) : null}
          </div>
          <ol>
            <li>
              {firstStep}
              <div className="to-do__lidl-img">
                <StaticImage src={lidl} alt={lidlImgAlt} />
              </div>
              <span>{firstStepSpan}</span>
            </li>
            <li>
              {secondStep}
              <span>{secondStepSpan}</span>
            </li>
            <li>
              {thirdStep}
              <span>{thirdStepSpan}</span>
            </li>
          </ol>
        </section>
        <section className="to-win">
          <div className="to-win__left-column">
            <p className="to-win__title">
              <span>{textSpan}</span>
              {text}
            </p>
            <p className="to-win__amount">{amount}</p>
          </div>
          <div className="to-win__right-column">
            {isMobile || isTablet || isDesktop ? (
              <StaticImage
                src={finishProductsSmall}
                alt={finishProductsImgAlt}
                className="to-win__img"
              />
            ) : (
              <StaticImage
                src={finishProductsBig}
                alt={finishProductsImgAlt}
                className="to-win__img"
              />
            )}
            <p className="to-win__more">
              <span>{moreSpan}</span>
              {more}
            </p>
          </div>
        </section>
        <section className="learn-more">
          <StaticImage
            src={drop}
            alt={dropImgAlt}
            className="learn-more__img learn-more__img--drop"
          />
          <div className="learn-more__paragraphs-container">
            <p className="learn-more__paragraph">{competitionTime}</p>
            <p className="learn-more__paragraph">{isMobile ? detailsMobile : details}</p>
          </div>
          <button type="button" onClick={toggleLdsModal} className="learn-more__button">
            {ldsButtonName}
          </button>
          <div className="winners">
            <button type="button" onClick={toggleWinnersList} className={winnersButton}>
              <a href="#top">{winnersText}</a>
            </button>
            <div className={winnersModal}>
              <div className="winners__content">
                <button type="button" className="close" onClick={toggleWinnersList}>
                  &times;
                </button>
                {isMobile ? (
                  <StaticImage src={winnersMobile} alt={modalAlt} className="winners__img" />
                ) : (
                  <StaticImage src={winners} alt={modalAlt} className="winners__img" />
                )}
              </div>
            </div>
          </div>
          <StaticImage
            src={lidl}
            alt={logoLidlImgAlt}
            className="learn-more__img learn-more__img--lidl"
          />
        </section>
        <LdsModal ldsBody={lds} isShown={isLdsModalShown} handleClose={toggleLdsModal} />
      </div>
    </>
  );
};

export default PromoPage;
